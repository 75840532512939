// combination of Formik Field component and bootstrap input fields
// ensures formik functionality while benefiting of bootstrap validation styling

import { Field, FieldAttributes, FormikErrors } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  FormFeedback,
  InputGroup,
  Label,
  UncontrolledTooltip,
} from "reactstrap";
import { Eye, EyeOff, Info } from "react-feather";
import { v4 as uuid } from "uuid";
import variables from "../../../styles/variables.module.scss";

type InputProps = FieldAttributes<any> & {
  valid: boolean;
  label?: string | null;
  description?: string | null;
  labelClassName?: string | null;
  tooltipText?: string | null;
  required?: boolean;
  type?: string;
  validate?: {
    touched: boolean;
    errors: FormikErrors<unknown>;
    values: Record<string, any>;
  };
};

const Input: React.FC<InputProps> = ({
  name,
  className,
  validate = {},
  onBlur,
  label,
  description,
  tooltipText,
  labelClassName,
  required,
  type,
  ...rest
}) => {
  const [blured, setBlured] = useState(false);
  // id cannot start with a number
  const tooltipId = useMemo(() => "id" + uuid(), []);

  useEffect(() => {
    if (
      validate &&
      validate.touched &&
      validate.touched[name] &&
      !blured &&
      required
    ) {
      setBlured(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, validate.touched]);

  const getValidationClass = useCallback(
    (validClass: string | null, invalidClass: string | null) => {
      if (validate.values && validate.errors && blured) {
        if ((required && !validate.values[name]) || validate.errors[name]) {
          return invalidClass;
        } else {
          return validClass;
        }
      }
      return null;
    },
    [blured, name, required, validate.errors, validate.values],
  );

  const fieldValidationClass = useMemo(() => {
    return getValidationClass("is-valid", "is-invalid");
  }, [getValidationClass]);

  const feedbackValidationClass = useMemo(() => {
    return getValidationClass(null, "invalid-feedback");
  }, [getValidationClass]);

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <>
      {label && (
        <span className="d-block mb-2">
          <Label
            for={name}
            style={{ display: "contents" }}
            className={labelClassName ? labelClassName : ""}
          >
            {label}
          </Label>
          {required && "*"}
          {tooltipText && (
            <span
              id={tooltipId}
              style={{
                color: "blue",
              }}
            >
              &nbsp;
              <Info color={variables["gray-600"]} size={18} strokeWidth={2.5} />
            </span>
          )}
        </span>
      )}
      {description && (
        <p className="text-muted">
          {description.split("\n").map((text: string) => (
            <li key={text}>{text}</li>
          ))}
        </p>
      )}
      {tooltipText && (
        <UncontrolledTooltip
          placement="top"
          target={tooltipId}
          autohide={false}
        >
          {tooltipText}
        </UncontrolledTooltip>
      )}
      {type === "password" ? (
        <InputGroup>
          <Field
            name={name}
            id={name}
            className={`${className} form-control valid ${fieldValidationClass}`}
            onBlur={() => {
              setBlured(true);
              onBlur && onBlur();
            }}
            type={passwordShown ? "text" : "password"}
            {...rest}
          />
          <Button
            onClick={togglePassword}
            style={{
              color: variables.black,
              backgroundColor: variables["gray-200"],
            }}
          >
            {passwordShown ? <EyeOff size={18} /> : <Eye size={18} />}
          </Button>
        </InputGroup>
      ) : (
        <Field
          name={name}
          id={name}
          className={`${className} form-control valid ${fieldValidationClass}`}
          onBlur={() => {
            setBlured(true);
            onBlur && onBlur();
          }}
          type={type}
          {...rest}
        />
      )}
      {feedbackValidationClass && (
        <FormFeedback
          valid={!!validate.errors[name]}
          className={`d-block valid ${feedbackValidationClass}`}
        >
          {validate.errors[name]}
        </FormFeedback>
      )}
    </>
  );
};

export default Input;
